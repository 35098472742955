<template>
  <ion-content v-if="listInvoice" class="ion-padding">
    <div class="d-flex justify-center fs-3 fw-bold mb-3">{{ $t('select_invoice') }}</div>
    <div class="mb-2" v-for="(item, index) in listInvoice" :key="index" @click="showInvoice(item.invoice_id)">
      <div class="fs-3 fw-bold">{{ item.invoice_number }}</div>
      <div class="fs-1">{{ $t('order') }} #{{ item.order_id }}</div>
    </div>
  </ion-content>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'modal-invoice-selection',

  setup() {},
  props: {
    listInvoice: {
      typr: Array,
      require: true
    }
  },
  methods: {
    showInvoice(id) {
      this.$emit('showInvoice', id);
    }
  }
});
</script>
