<template>
  <ion-page v-if="isShow">
    <div class="ion-padding">
      <ion-row>
        <ion-col size="9" class="ion-no-padding fs-3">{{ $t('orderB2b.order') }} #{{ item.id }}</ion-col>
        <ion-col size="3" class="ion-no-padding" style="text-align: right">
          <ion-icon :icon="closeOutline" class="icon-small" @click="closeModal()"></ion-icon>
        </ion-col>
      </ion-row>
      <ion-row class="ion-align-items-center">
        <ion-col size="8" class="ion-no-padding fs-15 d-flex">
          {{ formatTime(item.delivery_date) }}
          <span>&nbsp;{{ item.delivery_time }}</span>
        </ion-col>
        <ion-col size="4" class="ion-no-padding">
          <div class="ion-text-end">
            <ion-chip
              class="disable-click"
              :color="
                item.orderStatus.name === 'Cancelled'
                  ? 'danger'
                  : item.orderStatus.name === 'delivered'
                  ? 'light'
                  : 'primary'
              "
            >
              <ion-icon :icon="repeatOutline" class="fs-sm"></ion-icon>
              <ion-label class="fs-sm">{{ item.orderStatus.name }}</ion-label>
            </ion-chip>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <ion-col class="ion-no-padding fs-15">{{ item.orderItems[0].supplier?.name }}</ion-col>
      </ion-row>
    </div>

    <ion-item-divider style="min-height: 1px" class="bg-primary" />

    <ion-content>
      <ion-grid>
        <ion-row
          class="ion-padding sm-no-padding"
          v-for="(product, index) in detailPurchase || []"
          :key="index"
        >
          <div
            class="d-flex w-100"
            v-if="
              product.sku_id > 0 && ([10, 15].indexOf(product.order_item_status_id) > -1 || product.active)
            "
          >
            <ion-col size="3">
              <td-image
                class="image-product"
                :image="product.image"
                :imagePath="IMAGE_PATH.PRODUCT"
                :imageDefault="DEFAULT_PRODUCT_IMAGE"
              ></td-image>
            </ion-col>
            <ion-col size="9" class="pl-2">
              <div class="title fs-15" style="color: black; line-height: 22px">
                {{ product.sku_name }}
              </div>
              <div v-if="product.catch_weight" class="medium text-italic" style="font-size: 12px">
                <span class="text-weight-bold">{{ $t('weight') }}:</span>
                {{ product.catch_weight }}
              </div>
              <tooltip v-if="product.is_catch_weight" />
              <div class="d-flex mt-2 mb-1 fs-3 justify-space-between">
                <div class="fw-700 grey-color">
                  {{ formatNumber(product.sale_unit_price) }}
                </div>
                <div v-if="!product.is_order_by_weight">x{{ product.total_qty }}</div>
                <div v-else>{{ product.total_qty }} KG</div>
                <div class="fw-700 text-primary">
                  {{ formatNumber(product.total_price) }}
                </div>
              </div>
              <div class="d-flex mt-2 mb-1 fs-3 justify-end">
                <ion-chip
                  class="mx-0 disable-click"
                  v-if="product.order_item_status_id == 15 || product.order_item_status"
                >
                  <ion-label class="primary fs-12px">{{
                    product.order_item_status_id == 15
                      ? 'Waiting for Buyer Confirmation'
                      : product.order_item_status
                  }}</ion-label>
                </ion-chip>
              </div>
            </ion-col>
          </div>
        </ion-row>
        <ion-row class="ion-padding sm-no-padding" v-if="getCancelledBy">
          <ion-col size="12">
            <div class="message-bottom flex-1 py-1 px-2">
              <ion-text>
                <p class="fs-2 fw-600 py-1 my-0">Cancelled by {{ getCancelledBy }}</p>
                <div class="fs-13">
                  <p class="pb-1 my-0">Reason:</p>
                  <p class="pb-1 my-0">{{ getReasons }}</p>
                </div>
              </ion-text>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div class="line" />
      <div class="py-3 px-3">
        <DeliveryInstructionArea :instruction="item.delivery_instruction" :idModal="'view'" />
      </div>
      <div class="line" />
      <div class="pt-2 px-3">
        <ion-row class="mb-2">
          <ion-col>
            <div class="fs-11 fw-700">{{ $t('note_to_driver') }} ({{ $t('optional') }})</div>
            <span class="ion-text-start fs-1 medium">{{
              detail[0].description ? detail[0].description : '-'
            }}</span>
          </ion-col>
        </ion-row>
        <ion-item-divider style="min-height: 1px" />

        <ion-row class="ion-align-items-center pt-3">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.total') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary fw-700">
            {{ subTotalFormatted }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.tax') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary fw-700">
            {{ taxFormatted }}
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center pt-1">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.discount') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end grey-color">
            {{ discountFormatted }}
          </ion-col>
        </ion-row>
      </div>
    </ion-content>

    <div class="amount-footer" v-if="detailPurchase">
      <div class="ion-padding">
        <ion-row class="ion-align-items-center grand-total">
          <ion-col class="ion-no-padding title-left"> {{ $t('orderB2b.grandTotal') }} </ion-col>
          <ion-col class="ion-no-padding ion-text-end text-primary">
            {{ grandTotal }}
          </ion-col>
        </ion-row>
      </div>
    </div>
  </ion-page>
</template>

<script>
import Tooltip from '@/components/molecules/tooltip/Tooltip.vue';
import DeliveryInstructionArea from '@/modules/sale/components/AddAndEditDeliveryInstruction/DeliveryInstructionArea.vue';
import { DEFAULT_PRODUCT_IMAGE, IMAGE_PATH } from '@/modules/sale/constants/index';
import { MUTATIONS } from '@/modules/sale/store/notification/mutations';
import { ACTIONS } from '@/modules/sale/store/orders/purchase/actions';
import { MERCHANT_APP_NAME, USER_TYPE_ID } from '@/modules/shared/constants';
import { priceFormatter } from '@/utils/';
import { modalController } from '@ionic/vue';
import dayjs from 'dayjs';
import { closeOutline, repeatOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';
import OrderItemDetail from './OrderItemDetail';
const { mapActions, mapGetters } = createNamespacedHelpers('sale/purchase');
const { mapMutations: mapMutationsNotification } = createNamespacedHelpers('sale/notification');

export default defineComponent({
  name: 'order-detail',
  components: {
    Tooltip,
    DeliveryInstructionArea
  },
  data() {
    return {
      isShow: false,
      isloading: false,
      productImages: {}
    };
  },
  props: {
    item: {
      type: Object,
      default: null
    },
    closeDetail: {
      type: Function,
      required: true
    }
  },
  setup() {
    const user = ref(null);
    const image = ref(null);

    return {
      user,
      repeatOutline,
      closeOutline,
      image,
      IMAGE_PATH,
      DEFAULT_PRODUCT_IMAGE
    };
  },
  computed: {
    ...mapGetters(['detailPurchase', 'status']),
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    },
    formatDateTime() {
      return (value) => {
        return value ? new Date(value).toLocaleString() : null;
      };
    },
    detail() {
      return this.detailPurchase;
    },
    tax() {
      return this.detailPurchase
        .filter((v) => v.active && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.tax))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },
    subTotal() {
      return this.detailPurchase
        .filter((v) => v.active && v.total_price > 0 && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.total_price))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },

    discount() {
      return this.detailPurchase
        .filter((v) => v.active && v.total_price < 0 && ![-3, 10].includes(v.order_item_status_id))
        .map((v) => parseFloat(v.total_price))
        .reduce((total, v) => {
          return total + v;
        }, 0);
    },
    taxFormatted() {
      return priceFormatter(this.currencySymbol, this.tax.toFixed(2));
    },
    subTotalFormatted() {
      return priceFormatter(this.currencySymbol, this.subTotal.toFixed(2));
    },
    discountFormatted() {
      return priceFormatter(this.currencySymbol, this.discount.toFixed(2));
    },
    grandTotal() {
      const total = this.subTotal + this.tax + this.discount;
      return priceFormatter(this.currencySymbol, total.toFixed(2));
    },
    getCancelledBy() {
      const userTypeId = this.item?.orderLogs?.[0]?.user?.user_type_id;
      const status = this.item?.orderStatus.name;
      let cancelledBy = '';
      if (
        (userTypeId === USER_TYPE_ID.ADMIN || userTypeId === USER_TYPE_ID.SALE) &&
        status === 'Cancelled' &&
        this.item.app_name !== MERCHANT_APP_NAME
      ) {
        cancelledBy = 'Supplier';
      } else if (status === 'Cancelled' && this.item.app_name === MERCHANT_APP_NAME) {
        cancelledBy = this.item.app_name;
      }
      return cancelledBy;
    },
    getReasons() {
      if (this.item.reason) {
        return `${this.item.reason_cancel} - ${this.item.reason}`;
      }
      const message = this.item.reason_cancel;
      return message;
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
    this.getData();
    await this[MUTATIONS.OPEN_MODAL_DETAILS]({
      id: 0
    });
  },
  methods: {
    ...mapMutationsNotification([MUTATIONS.OPEN_MODAL_DETAILS]),
    ...mapActions([ACTIONS.GET_PURCHASE_DETAIL]),
    async closeModal() {
      await this.closeDetail();
    },
    formatNumber(number) {
      return priceFormatter(this.currencySymbol, number.toFixed(2));
    },
    formatTime(params) {
      return dayjs(params).format('D MMM YYYY ');
    },
    async getData() {
      await this[ACTIONS.GET_PURCHASE_DETAIL]({ orderId: this.item.id });
      if (this.status) {
        this.isShow = true;
      }
    },

    async openModalDetail(item, product) {
      const modal = await modalController.create({
        component: OrderItemDetail,
        cssClass: 'order-item-detail',
        componentProps: {
          title: 'Delivery Data & Time',
          product: product,
          item: item,
          close: async () => {
            await modal.dismiss();
          }
        }
      });
      return modal.present();
    }
  }
});
</script>

<style lang="scss" scoped>
.fs-15 {
  font-size: 15px;
  line-height: 22px;
  color: #9e9e9e;
}
.fs-11 {
  font-size: 11px;
}
.grey-color {
  color: var(--ion-color-grey5);
}
.image-product {
  width: 75px;
  height: 75px;
}
@media only screen and (max-width: 320px) {
  .fs-15,
  .fs-sm {
    font-size: 12px !important;
  }
  .fs-3 {
    font-size: 14px !important;
  }
  .sm-no-padding {
    padding-left: 0px;
    padding-right: 0px;
  }
  .image-product {
    width: 70px;
    height: 70px;
  }
}
.message-bottom {
  background-color: #f5f5f5;
}
.line {
  height: 1px;
  width: 100vw;
  background: #00676a;
  color: #00676a;
}
.grand-total {
  font-size: 16px;
  font-weight: bold;
}
</style>
