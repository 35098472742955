<template>
  <ion-content v-if="product">
    <ion-header>
      <ion-toolbar>
        <div class="d-flex align-center px-2">
          <ion-icon :icon="arrowBackOutline" class="fs-5" @click="closeModal()"></ion-icon>
          <div class="pl-3 fw-700">{{ $t('orderB2b.orderItemDetail') }}</div>
        </div>
      </ion-toolbar>
    </ion-header>

    <div class="ion-padding mt-1 bg-white fs-12">
      <div class="d-flex ion-align-items-center justify-space-between">
        <div>
          <ion-label class="fs-3 medium-9 fw-500" v-if="product.delivery_address"
            >{{ $t('orderB2b.delivery_address') }}:</ion-label
          >
        </div>
        <div>
          <ion-label class="fs-3 fw-500">{{ $t('orderB2b.order') }} #{{ product.id }}</ion-label>
        </div>
      </div>

      <div class="d-flex ion-align-items-center justify-space-between" v-if="product.delivery_address">
        <ion-label class="fs-3 mt-2 fw-500" style="line-height: 24px">{{
          product.delivery_address
        }}</ion-label>
      </div>

      <ion-item-divider style="min-height: 1px" class="mt-6 bg-primary" />
    </div>

    <ion-grid class="bg-white mt-3">
      <ion-row class="align-center">
        <ion-col size="3">
          <div class="d-flex">
            <ion-img style="width: 48px; height: 48px" class="ml-2" :src="image(product.image)"></ion-img>
          </div>
        </ion-col>
        <ion-col size="9" class="fs-3">
          <div class="mr-5">{{ product.sku_name }}</div>
          <div class="d-flex justify-space-between mt-2 mb-2 medium-9">
            <div v-if="product.catch_weight" class="medium text-italic" style="font-size: 12px">
              <span class="text-weight-bold">{{ $t('Weights') }}:</span>
              {{ product.catch_weight }} {{ $t('kg') }}
            </div>
            <div class="fw-700">{{ priceFormatter(currencySymbol, product.sale_unit_price) }}</div>
            <div v-if="!product.is_order_by_weight">x{{ product.total_qty }}</div>
            <div v-else>{{ product.total_qty }} KG</div>
            <div class="fw-700 text-primary">{{ priceFormatter(currencySymbol, product.total_price) }}</div>
          </div>
        </ion-col>
        <ion-col>
          <div class="mt-3 mb-3 ion-text-end text-primary">
            {{ product.delivery_status ? product.delivery_status : '-' }}
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
    <div class="d-flex justify-center" @click="openModalDetail(product)">
      <ion-button color="light" size="small" v-if="product.dispute_status != null">
        <ion-icon slot="start" :icon="repeatOutline" class="mr-2 text-primary"></ion-icon>
        <span class="text-primary">{{ $t('orderB2b.viewDispute') }}</span>
      </ion-button>
      <ion-button color="light" size="small" v-else>
        <ion-icon slot="start" :icon="alertCircleOutline" class="mr-2 text-danger"></ion-icon>

        <span class="text-danger">{{ $t('orderB2b.reportProblem') }}</span>
      </ion-button>
    </div>
  </ion-content>
</template>

<script>
import { priceFormatter } from '@/utils/';
import { loadImage } from '@/utils/image';
import { modalController } from '@ionic/vue';
import {
  addOutline,
  alertCircleOutline,
  arrowBackOutline,
  chevronForwardOutline,
  closeOutline,
  removeOutline,
  repeatOutline,
  trashOutline
} from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import ReportOrderItemDetail from './ReportOrderItemDetail';
export default defineComponent({
  name: 'RepeatOrder',
  props: {
    close: {
      type: Function,
      default: () => {}
    },
    product: {
      type: Object,
      default: null
    },
    item: {
      type: Object,
      default: null
    }
  },
  setup() {
    const user = ref(null);
    return {
      user,
      repeatOutline,
      closeOutline,
      arrowBackOutline,
      chevronForwardOutline,
      trashOutline,
      removeOutline,
      addOutline,
      alertCircleOutline,
      priceFormatter
    };
  },
  computed: {
    image() {
      return (value) => {
        if (value) {
          return loadImage(value, 'small');
        }
      };
    },
    currencySymbol() {
      return this.user ? this.user.country.currency_symbol : '';
    }
  },
  inject: ['$storage'],
  async mounted() {
    this.user = await this.$storage.getUser();
  },
  methods: {
    async closeModal() {
      await this.close();
    },
    async openModalDetail(product) {
      const modal = await modalController.create({
        component: ReportOrderItemDetail,
        cssClass: 'order-detail order-item-detail',
        componentProps: {
          product: product,
          item: this.item,
          close: async () => {
            await modal.dismiss();
          }
        }
      });
      return modal.present();
    }
  }
});
</script>

<style lang="scss" scoped>
ion-content::part(scroll) {
  overflow: hidden;
}
.item {
  font-size: 12px;
  label {
    font-style: italic;
    font-size: 10px;
  }
}
ion-button {
  --border-radius: 50px;
}
.order-item-detail {
  --backdrop-opacity: 0.4 !important;
}
</style>
