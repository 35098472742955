<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="#"
            class="back-btn"
            text=""
            @click="$emit('closePage')"
            :icon="chevronBackOutline"
          >
          </ion-back-button>
        </ion-buttons>
        <ion-label class="head-title ml-2">{{ $t('invoice_cn.invoice') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="isOpenLoadingref" class="loading">
        <ion-spinner name="circles"></ion-spinner>
      </div>
      <div v-else class="bg-content">
        <div class="column bg-white">
          <ion-text color="primary" class="head-title mb-2"> {{ invoiceDetail.supplier_name }} </ion-text>
          <ion-text class="my-1 bold"> #{{ invoiceDetail.invoice_number }} </ion-text>
          <div class="d-flex justify-space-between">
            <ion-text class="text-grey">{{ $t('invoice_date') }} </ion-text>
            <ion-text class="text-grey"> {{ formatTime(invoiceDetail?.actual_delivery_date) }} </ion-text>
          </div>
          <div class="d-flex justify-space-between mt-1">
            <ion-text class="text-grey">{{ $t('payment_term') }} </ion-text>
            <ion-text class="text-grey"> {{ paymentHistories.creditTerm }} </ion-text>
          </div>
          <ion-text color="primary" class="mt-3 mb-2 bold">
            {{ $t('invoice_cn.grand_total') }}
          </ion-text>
          <ion-text color="primary" class="mb-3 bold price_inv">
            {{ priceFormatter(currencySymbol, invoiceDetail.grand_total) }}</ion-text
          >
          <div class="column bg-content ion-padding">
            <ion-text color="primary" class="bold">{{ $t('invoice_cn.bill_to') }}:</ion-text>
            <ion-text class="mt-2 mb-1">{{ invoiceDetail.billing_name }}</ion-text>
            <ion-text>{{ invoiceDetail.billing_address }}</ion-text>
          </div>
          <ion-grid class="w-100 mx-0 px-0">
            <ion-row>
              <ion-col size="12">
                <ion-text
                  class="fw-bold"
                  :class="{
                    'text-primary-orange-500':
                      paymentHistories.paymentStatus === PAYMENT_HISTORY_STATUS.PARTIAL_PAID,
                    'text-primary-green-500': paymentHistories.paymentStatus === PAYMENT_HISTORY_STATUS.PAID,
                    'text-error-500': paymentHistories.paymentStatus === PAYMENT_HISTORY_STATUS.UNPAID
                  }"
                  >{{ paymentHistories.paymentStatus.toUpperCase() }}</ion-text
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text>{{ $t('invoice_total') }}</ion-text>
              </ion-col>
              <ion-col size="6">
                <ion-text
                  :class="{
                    'text-error-500': paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.PAID
                  }"
                  class="fw-500"
                  >{{ $t('amount_due') }}</ion-text
                >
              </ion-col>
            </ion-row>
            <ion-row>
              <ion-col size="6">
                <ion-text class="fs-5 fw-bold">{{
                  priceFormatter(currencySymbol, paymentHistories.amount)
                }}</ion-text>
              </ion-col>
              <ion-col size="6" v-if="paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.PAID">
                <ion-row>
                  <ion-text
                    :class="{
                      'text-error-500': paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.PAID
                    }"
                    class="fw-bold fs-5"
                    >{{ priceFormatter(currencySymbol, paymentHistories.amountDue) }}</ion-text
                  >
                </ion-row>
              </ion-col>
              <ion-col v-else>
                <ion-text class="fw-bold fs-5"> - </ion-text>
              </ion-col>
            </ion-row>
            <ion-row class="align-center space-between bold mt-2">
              <ion-button
                @click="$emit('openPaymentHistory', paymentHistories)"
                mode="ios"
                fill="outline"
                color="primary"
                class="w-100 h-3rem"
                :disabled="paymentHistories.paymentStatus === PAYMENT_HISTORY_STATUS.UNPAID"
              >
                {{ $t('view_payment_history') }}
                <ion-icon color="primary" class="f-icon" :icon="chevronForwardOutline"></ion-icon>
              </ion-button>
            </ion-row>
          </ion-grid>
          <div class="spacer"></div>
          <ion-row class="my-2">
            <ion-text class="fw-bold text-gray-600">{{ $t('items').toUpperCase() }}</ion-text>
          </ion-row>
          <div class="line"></div>
          <div class="my-3">
            <ion-row
              v-for="OrderItem in invoiceDetail.OrderItem"
              :key="OrderItem.id"
              class="align-start row mb-2"
            >
              <ion-col>
                <ion-text>{{ OrderItem.sku_name }}</ion-text>
              </ion-col>
              <div class="column text-right">
                <ion-text>x{{ OrderItem.total_qty }}</ion-text>
                <ion-text color="primary">{{
                  priceFormatter(currencySymbol, OrderItem.total_price)
                }}</ion-text>
              </div>
            </ion-row>
          </div>
          <div class="spacer"></div>
          <ion-row class="my-2">
            <ion-text class="fw-bold text-gray-600">{{ $t('summary').toUpperCase() }}</ion-text>
          </ion-row>
          <div class="line"></div>
          <ion-row class="align-center space-between my-2 fw-600">
            <ion-text class="text-grey">{{ $t('invoice_cn.sub_total') }}</ion-text>
            <ion-text color="primary">{{ priceFormatter(currencySymbol, subTotal()) }}</ion-text>
          </ion-row>
          <ion-row class="align-center space-between fw-600">
            <ion-text class="text-grey">{{ $t('invoice_cn.tax') }}</ion-text>
            <ion-text color="primary">{{ priceFormatter(currencySymbol, tax()) }}</ion-text>
          </ion-row>
          <ion-row class="align-center space-between mt-2 fw-600 bg-gray-500">
            <ion-text>{{ $t('grand_total') }}</ion-text>
            <ion-text>{{ priceFormatter(currencySymbol, invoiceDetail.grand_total) }}</ion-text>
          </ion-row>
          <div class="line my-2"></div>
          <ion-row
            class="align-center space-between text-primary-green-500 fw-600"
            v-if="paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.UNPAID"
          >
            <ion-text>{{ $t('paid_amount') }}</ion-text>
            <ion-text v-if="!paymentHistories.customerCreditUsed"
              >-{{ priceFormatter(currencySymbol, paymentHistories.paidAmount) }}</ion-text
            >
            <ion-text v-else
              >-{{
                priceFormatter(
                  currencySymbol,
                  paymentHistories.paidAmount - paymentHistories.customerCreditUsed
                )
              }}</ion-text
            >
          </ion-row>
          <ion-row
            class="align-center space-between mt-2 text-primary-green-500 fw-600"
            v-if="
              [PAYMENT_HISTORY_STATUS.PARTIAL_PAID, PAYMENT_HISTORY_STATUS.PAID].includes(
                paymentHistories.paymentStatus
              )
            "
          >
            <ion-text>{{ $t('applied_credits') }}</ion-text>
            <ion-text>-{{ priceFormatter(currencySymbol, paymentHistories.customerCreditUsed) }}</ion-text>
          </ion-row>
          <div
            class="line my-2"
            v-if="paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.UNPAID"
          ></div>
          <ion-row
            class="align-center space-between bold"
            v-if="paymentHistories.paymentStatus !== PAYMENT_HISTORY_STATUS.PAID"
          >
            <ion-text>{{ $t('amount_due').toUpperCase() }}</ion-text>
            <ion-text>{{ priceFormatter(currencySymbol, paymentHistories.amountDue) }}</ion-text>
          </ion-row>
        </div>
      </div>
    </ion-content>
    <ion-footer v-if="!isOpenLoadingref">
      <div class="column bg-white">
        <ion-text class="mb-2">{{ $t('invoice_cn.view_document') }}</ion-text>
        <ion-button @click="handleDownloadInvoice" class="invoice-btn mb-2" color="primary">
          <ion-spinner v-if="isDownloadInvoice" name="dots"></ion-spinner>
          <ion-text v-else> {{ $t('invoice_cn.invoice') }} (PDF) </ion-text>
        </ion-button>
      </div>
    </ion-footer>
  </ion-page>
  <ion-modal :is-open="isShowDocPreview">
    <pdf-preview :pdfUrls="[pdfUrl]" @on-dismiss="setShowDocPreview(false)"></pdf-preview>
  </ion-modal>
</template>

<script>
import { apolloClient } from '@/main';
import {
  downloadInvoice,
  saleDownloadInvoiceByInvoiceId,
  saleGetInvoiceById
} from '@/modules/sale/services/graphql';
import { ACTIONS } from '@/modules/sale/store/payment/actions';
import PdfPreview from '@/modules/shared/components/PdfPreview';
import { PAYMENT_HISTORY_STATUS } from '@/modules/shared/constants';
import { toastError } from '@/modules/shared/utils/';
import { priceFormatter } from '@/utils';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import dayjs from 'dayjs';
import { checkmarkOutline, chevronBackOutline, chevronForwardOutline, closeOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('sale/payment');

export default defineComponent({
  name: 'ViewInvoice',
  inject: ['$storage'],
  components: {
    PdfPreview
  },
  emits: ['closePage', 'openPaymentHistory'],
  props: {
    detailOrderId: {
      type: Number,
      default: 0
    },
    invoiceId: {
      type: Number,
      default: 0
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup() {
    const invoiceDetail = ref({});
    const pdfUrl = ref('');
    const isOpenLoadingref = ref(true);
    const isDownloadInvoice = ref(false);
    const isShowDocPreview = ref(false);
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const setOpenDownload = (state) => (isDownloadInvoice.value = state);
    const setShowDocPreview = (state) => (isShowDocPreview.value = state);
    return {
      chevronBackOutline,
      chevronForwardOutline,
      checkmarkOutline,
      closeOutline,
      invoiceDetail,
      isOpenLoadingref,
      setOpenLoading,
      isDownloadInvoice,
      setOpenDownload,
      pdfUrl,
      Browser,
      isShowDocPreview,
      setShowDocPreview,
      paymentHistories: ref({}),
      PAYMENT_HISTORY_STATUS,
      priceFormatter
    };
  },
  async mounted() {
    const { tenant } = await this.$storage.getUser();
    await this.getPaymentHistory(tenant.id);
    this.checkInvoiceAndCreditNotes();
  },
  computed: {
    ...mapGetters(['paymentHistory', 'error'])
  },
  methods: {
    ...mapActions([ACTIONS.GET_PAYMENT_HISTORY]),
    async getPaymentHistory(tenantId) {
      await this[ACTIONS.GET_PAYMENT_HISTORY]({
        tenantId,
        invoiceId: this.invoiceId
      });
      const { paymentHistory } = this.paymentHistory;
      this.paymentHistories = paymentHistory;
    },
    async checkInvoiceAndCreditNotes() {
      this.setOpenLoading(true);
      const { data } = await apolloClient.query({
        query: saleGetInvoiceById,
        variables: {
          invoiceId: this.invoiceId
        }
      });
      this.invoiceDetail = data.saleGetInvoiceById;
      this.setOpenLoading(false);
    },

    formatTime(params) {
      return dayjs(params).format('ddd, DD MMM YYYY');
    },
    async handleDownloadInvoice() {
      this.setOpenDownload(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: !this.detailOrderId ? saleDownloadInvoiceByInvoiceId : downloadInvoice,
          variables: {
            ...(!this.detailOrderId && { invoiceId: this.invoiceId }),
            ...(this.detailOrderId && { orderId: this.detailOrderId })
          }
        });
        this.pdfUrl = !this.detailOrderId ? data.saleDownloadInvoiceByInvoiceId : data.downloadInvoice;
        if (!this.pdfUrl) {
          toastError(this.$t('invoice_cn.download_file_fail'));
          return;
        }
        if (Capacitor.isNativePlatform()) {
          this.setShowDocPreview(true);
        } else {
          await Browser.open({ url: data.saleDownloadInvoiceByInvoiceId || data.downloadInvoice });
        }
      } catch (e) {
        toastError(this.$t('invoice_cn.download_file_fail'));
        console.log(e);
      } finally {
        this.setOpenDownload(false);
      }
    },

    tax() {
      return this.invoiceDetail.OrderItem?.reduce((acc, item) => acc + item.tax, 0);
    },
    subTotal() {
      return this.invoiceDetail.OrderItem?.reduce((acc, item) => acc + item.total_price, 0);
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
