<template>
  <ion-item v-show="showUpdatedItem">
    <ion-label>
      <p>
        {{ item.sku?.name }}
      </p>
      <p>{{ showPrice(item.sale_unit_price) }} ({{ item.qty }} {{ item.sku.oom.name }}(s))</p>
      <ion-grid>
        <ion-row>
          <ion-col size="2">
            <b> {{ $t('previous') }}: </b>
          </ion-col>
          <ion-col size="10"> </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="3"> {{ showPrice(oldItem.sale_unit_price) }} </ion-col>
          <ion-col v-if="!item.is_order_by_weight" size="1"> x {{ oldItem.total_qty }} </ion-col>
          <ion-col v-else size="1"> {{ oldItem.total_qty }} {{ $t('kg') }} </ion-col>
          <ion-col size="5" class="d-flex justify-end">=</ion-col>
          <ion-col size="3"> {{ showPrice(oldItem.total_price) }} </ion-col>
        </ion-row>
        <ion-row class="updated">
          <ion-col size="2">
            <b> {{ $t('updated') }}: </b>
          </ion-col>
          <ion-col size="10"> </ion-col>
        </ion-row>
        <ion-row class="deleted" v-if="item.isDelete">
          <ion-col size="3"> {{ showPrice(item.sale_unit_price) }} </ion-col>
          <ion-col v-if="!item.is_order_by_weight" size="1"> x {{ 0 }} </ion-col>
          <ion-col v-else size="1"> {{ 0 }} {{ $t('kg') }} </ion-col>
          <ion-col size="5" class="d-flex justify-end">=</ion-col>
          <ion-col size="3"> {{ $t('removed') }}</ion-col>
        </ion-row>
        <ion-row class="updated" v-else>
          <ion-col size="3"> {{ showPrice(item.sale_unit_price) }} </ion-col>
          <ion-col v-if="!item.is_order_by_weight" size="1"> x {{ item.qty }} </ion-col>
          <ion-col v-else size="1"> {{ item.qty }} {{ $t('kg') }} </ion-col>
          <ion-col size="5" class="d-flex justify-end">=</ion-col>
          <ion-col size="3"> {{ showPrice(item.origin_unit_price) }} </ion-col>
        </ion-row>
      </ion-grid>
    </ion-label>
  </ion-item>
</template>
<script>
import { showPriceWithCurrencySymbol } from '@/modules/shared/utils/';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    item: {
      required: true
    },
    oldItem: {
      required: true
    },
    currencySymbol: {
      required: true
    }
  },

  setup(props) {
    const showPrice = showPriceWithCurrencySymbol(props.currencySymbol);
    return {
      showPrice
    };
  },

  computed: {
    // check updated items
    showUpdatedItem() {
      let isItemChanges = false;
      if (this.item.isDelete) {
        isItemChanges = true;
      } else {
        isItemChanges = parseInt(this.oldItem.total_qty) === parseInt(this.item.qty) ? false : true;
      }
      return isItemChanges;
    }
  }
});
</script>
<style lang="scss" scoped>
ion-item {
  font-size: 14px;
  --padding-start: 0px;
}
.updated {
  color: green;
}
.deleted {
  color: red;
}
</style>
