<template>
  <ion-page>
    <!-- header -->
    <ion-header>
      <ion-toolbar class="">
        <ion-button slot="start" fill="clear" @click="$emit('close-modal')">
          <ion-icon slot="icon-only" size="small" :icon="arrowBackOutline"></ion-icon>
        </ion-button>
        <ion-label class="label pr-3 fs-3 fw-600">{{ $t('delivery_date_time') }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content v-if="viewEntered">
      <div>
        <div class="date mt-2 pa-2">
          <ion-label class="fs-3 text-uppercase" color="grey">{{ $t('select_delivery_date') }}</ion-label>
        </div>
        <ion-slides class="slides-cate" :options="slideOpts">
          <ion-slide
            v-for="(date, index) in deliveryDates"
            :key="index"
            :class="!date.isAvailable ? 'not-available' : ''"
          >
            <div
              :class="['d-flex flex-column align-center', !date.isAvailable ? 'disable-click' : '']"
              @click="selectDate(date.data)"
            >
              <div class="d-block date" :class="{ active: checkActive(date.data) }">
                <div class="pb-2 fs-3 text-grey">
                  {{ index === 0 ? $t('today') : dayjs(date.data).format('dd') }}
                </div>
                <ion-text>
                  {{ dayjs(date.data).format('DD') + '/' + dayjs(date.data).format('MM') }}
                </ion-text>
              </div>
              <ion-text class="fs-1">{{ !date.isAvailable ? $t('unavailable') : '' }}</ion-text>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
      <ion-label class="fs-3 text-uppercase pt-4 pl-2" color="grey">{{
        $t('select_delivery_time')
      }}</ion-label>
      <ion-grid class="time pa-2">
        <ion-row>
          <ion-col>
            <ion-radio-group :value="selectedTime">
              <ion-item
                lines="none"
                class="ion-no-padding mb-1"
                v-model="selectedTime"
                v-for="(item, index) in deliveryTimes"
                :key="index"
                @click="selectTime(item.deliveryTimes)"
              >
                <ion-label color="dark">
                  <div>{{ item.deliveryTimes }}</div>
                </ion-label>
                <ion-radio
                  :value="item.deliveryTimes"
                  :disabled="!isTimeSlotAvailable(selectedDate, item)"
                ></ion-radio>
              </ion-item>
            </ion-radio-group>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-footer v-if="viewEntered">
      <ion-toolbar class="pa-2">
        <ion-button color="primary" expand="block" @click="confirm()" :disabled="!selectedTime">
          <span>{{ $t('confirm') }}</span>
        </ion-button>
      </ion-toolbar>
    </ion-footer>

    <ion-loading
      :is-open="isOpenRef"
      cssClass="my-custom-class"
      :message="`${$t('please_wait')}. . .`"
      @didDismiss="setOpen(false)"
    >
    </ion-loading>
  </ion-page>
</template>

<script>
import dayjs from 'dayjs';
import { defineComponent, ref } from 'vue';

import { apolloClient } from '@/main';
import { getAvailableDeliverySlots } from '@/modules/b2b/services/graphql';
import { ACTIONS } from '@/modules/sale/store/orders/purchase/actions';
import { arrowBackOutline } from 'ionicons/icons';
import { createNamespacedHelpers } from 'vuex';

const { mapActions } = createNamespacedHelpers('sale/purchase');

export default defineComponent({
  name: 'Delivery-dateTime-order',

  setup() {
    const slideOpts = {
      initialSlide: 0,
      speed: 400,
      slidesPerView: 'auto'
    };

    const viewEntered = ref(false);
    const deliveryTimes = ref([]);
    const deliveryDates = ref([]);
    const selectedDate = ref(null);
    const selectedTime = ref(null);
    const isOpenRef = ref(false);
    const deliverySlots = ref([]);
    const setOpen = (state) => (isOpenRef.value = state);

    return {
      deliveryDates,
      deliveryTimes,
      selectedDate,
      selectedTime,
      deliverySlots,

      arrowBackOutline,
      slideOpts,
      dayjs,
      isOpenRef,
      setOpen,
      viewEntered
    };
  },
  props: {
    items: {
      type: Array,
      default: function () {
        return [];
      }
    },
    deliveryDate: {
      type: String,
      default: null
    },
    deliveryTime: {
      type: String,
      default: null
    },
    orderEditDetail: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },

  emits: ['confirm', 'close-modal'],

  computed: {
    checkActive() {
      return (date) => {
        if (this.selectedDate === date) {
          return true;
        }
      };
    }
  },
  inject: ['$storage'],

  created() {
    this.setOpen(true);
  },
  async mounted() {
    // this.getListDate();
    this.selectedDate = this.dayjs(this.deliveryDate).format('YYYY-MM-DD');
    await this.handleGetDeliveryDate();
    const user = await this.$storage.getUser();
    this.deliveryTimes = await this[ACTIONS.GET_DELIVERY_TIMES](user.tenant.id);
    this.selectedTime = this.deliveryTime;
    this.viewEntered = true;
    this.setOpen(false);
  },
  watch: {
    selectedDate(selectedDate) {
      const selectedDeliveryTime = this.deliveryTimes.find((dt) => dt.deliveryTimes === this.selectedTime);
      if (!this.isTimeSlotAvailable(selectedDate, selectedDeliveryTime)) {
        this.selectedTime = this.deliveryTimes.find((dt) =>
          this.isTimeSlotAvailable(selectedDate, dt)
        )?.deliveryTimes;
      }
    }
  },
  methods: {
    ...mapActions([ACTIONS.GET_DELIVERY_TIMES]),
    async handleGetDeliveryDate() {
      const currentTime = new Date();
      const { data } = await apolloClient.query({
        query: getAvailableDeliverySlots,
        variables: {
          userActionDatetime: dayjs(currentTime).format('YYYY-MM-DDTHH:mm:ssZ'),
          items: this.items,
          tenantId: this.orderEditDetail.tenant_id,
          daysLimit: 30,
          isNewOrder: false
        }
      });

      this.deliverySlots = data.getAvailableDeliverySlots;

      this.deliveryDates = this.deliverySlots.map((item) => ({
        data: item.date,
        isAvailable: item.isAvailable
      }));
    },
    isTimeSlotAvailable(date, deliveryTime) {
      let isAvailable = false;

      for (const ds of this.deliverySlots) {
        if (ds.date === date) {
          for (const slot of ds.slots) {
            if (slot.label === deliveryTime.deliveryTimes) {
              isAvailable = slot.isAvailable;
              break;
            }
          }
        }
      }

      return isAvailable;
    },
    selectDate(date) {
      this.selectedDate = date;
    },
    selectTime(time) {
      this.selectedTime = time;
    },

    confirm() {
      this.$emit('confirm', {
        deliveryDate: this.dayjs(this.selectedDate).format('YYYY-MM-DD'),
        deliveryTime: this.selectedTime
      });
    }
  }
});
</script>

<style scoped lang="scss">
@import '@/assets/css/modal/deliveryTime.scss';
</style>
